import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "./assets/font/Source Han Sans CN Regular.css"
import gobal from "./globalFun"
import axios from './axios'
import * as echarts from 'echarts'//5.0版本之后要有as echarts这个
// import { Message } from 'element-ui';

import VueParticles from 'vue-particles'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.use(VueParticles)

// 除数，被除数， 保留的小数点后的位数
Vue.prototype.NumberDiv = function (arg1, arg2, digit) {
    if (undefined === digit || null === digit) {
        digit = 2;
    }
    var t1 = 0, t2 = 0, r1, r2;
    try {
        t1 = arg1.toString().split(".")[1].length
    } catch (e) {
    }
    try {
        t2 = arg2.toString().split(".")[1].length
    } catch (e) {
    }
    r1 = Number(arg1.toString().replace(".", ""))
    r2 = Number(arg2.toString().replace(".", ""))
    //获取小数点后的计算值
    var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
    var result2 = result.split(".")[1];
    if (undefined === result2) {
        return Number(result.split(".")[0] + ".00")
    }
    result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

    return Number(result.split(".")[0] + "." + result2);
}

Vue.prototype.NumberSub = function (arg1, arg2) {
    var re1, re2, m, n;
    try {
        re1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        re1 = 0;
    }
    try {
        re2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        re2 = 0;
    }
    m = Math.pow(10, Math.max(re1, re2));
    n = (re1 >= re2) ? re1 : re2;
    return Number(((arg1 * m - arg2 * m) / m).toFixed(n));
}

Vue.prototype.NumberMul = function (arg1, arg2, digit) {
    if (undefined === digit || null === digit) {
        digit = 2;
    }
    var m = 0;
    var s1 = arg1.toString();
    var s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
    }

    var result = (Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)).toString();
    var result2 = result.split(".")[1];
    if (undefined === result2) {
        return Number(result.split(".")[0] + ".00")
    }
    result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

    return Number(result.split(".")[0] + "." + result2);
}

Vue.prototype.NumberAdd = function (arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

Vue.prototype.StateFormat = function (row, column, cellValue) {
    if (cellValue === null || cellValue === undefined){
        return ""
    }
    cellValue += '';
    if (!cellValue.includes('.')) cellValue += '.';
    return cellValue.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ',';
    }).replace(/\.$/, '');
}
Vue.prototype.Rounding = function (row, column) {
    if (row[column.property] === null || row[column.property] === undefined){
        return ""
    }
    return parseFloat(row[column.property]).toFixed(2)
}
Vue.prototype.Percent = function (row, column) {
    if (row[column.property] === null || row[column.property] === undefined){
        return ""
    }
    return parseFloat(row[column.property]).toFixed(2) + "%"
}

Vue.directive('loadmore', {
    bind(el, binding) {

        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {

            const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight

            if (CONDITION) {
                binding.value()
            }
        })
    }
})

// Vue.prototype.$message.success = function(msg) {
//     return Message.success({
//         message: msg,
//         duration: 1000
//     });
// };

Vue.use(Element)
// require('./mock')
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
