<template>
  <div style="height: 100%">
    <el-row class="row-bg" justify="center" align="middle">
      <el-col :span="24" justify="center" align="middle" style="height: 100%">
        <vue-particles
            class="login-bg"
            color="#39AFFD"
            :particleOpacity="0.7"
            :particlesNumber="100"
            shapeType="circle"
            :particleSize="4"
            linesColor="#8DD1FE"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
        />
        <div class="login-box">
          <h2>天脉移动广告平台</h2>
          <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px" class="loginForm">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="loginForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="loginForm.password" type="password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
              <el-button @click="resetForm('loginForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-row justify="center" style="margin-bottom: 0" class="footer">
      <el-col :span="24">
        <span style="font-size: 14px">北京天然合一科技有限公司 </span>&nbsp;&nbsp;&nbsp;
        <el-link href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021025987号-3</el-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var form = new FormData();
          form.append('username', this.loginForm.username);
          form.append('password', this.loginForm.password);

          let config = {
            headers: {'Content-Type': 'multipart/form-data'}
          }; //添加请求头
          this.$axios.post('/login', form, config).then(res => {
            const jwt = res.headers['authorization']

            this.$store.commit('SET_TOKEN', jwt)

            this.$router.push("/")

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }

  }
}
</script>

<style scoped>
.login-bg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/background/alessio-soggetti-zxcBR3zNc7I-unsplash.jpg") no-repeat;
  background-size: 100% 100%;
}

.el-row {
  background-color: #FAFAFA;
  text-align: center;
}

.login-box {
  height: 300px;
  width: 600px;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .12), 0 0 24px rgba(0, 0, 0, .04);
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  bottom: 0;
  margin: auto;

}

.loginForm {
  width: 500px;
  display: inline-block;
}

.row-bg {
  height: 97%;
  padding: 10px 0;
  background-color: #f9fafc;
}

.footer {
  margin-bottom: 0;
  height: 2%;
}

</style>