import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    state: {
        menuList: [],
        permList: [],
        userinfo:{
            id: null,
            username: ''
        },
        hasRoute: false,
        editableTabsValue: 'Index',
        editableTabs: [{
            title: '首页',
            name: 'Index',
        }]
    },
    getters: {},
    mutations: {
        setMenuList: (state, menuList) => {
            state.menuList = menuList
        },
        setPermList: (state, permList) => {
            state.permList = permList
        },
        setHasRoute: (state, hasRoute) => {
            state.hasRoute = hasRoute
        },
        setUserInfo: (state,userinfo) => {
            state.userinfo = userinfo;
        },
        addTab(state, tab) {
            let index = state.editableTabs.findIndex(e => e.name === tab.name)
            if (index === -1) {
                state.editableTabs.push({
                    title: tab.title,
                    name: tab.name,
                });

            }
            state.editableTabsValue = tab.name;
        },
        resetState: (state) => {
            state.menuList = []
            state.permList = []

            state.hasRoute = false
            state.editableTabsValue = 'Index'
            state.editableTabs = [{
                title: '首页',
                name: 'Index',
            }]
            state.userinfo = {
            }
        }
    },
    actions: {},
    modules: {}
}
