import axios from "axios"
import router from "./router";
import Element from "element-ui";

axios.defaults.baseURL = "http://platapi.adtianmai.com"
// axios.defaults.baseURL = "http://172.16.0.2:8081"
// axios.defaults.baseURL = "http://127.0.0.1:8081"

const request = axios.create({
    timeout: 60000,
    headers: {
        'Content-Type': "application/json;charset=utf-8"
    }
})

request.interceptors.request.use(config => {
    config.headers['Authorization'] = localStorage.getItem("token")
    return config
})

request.interceptors.response.use(response => {
        if (response.status === 200 && response.config.responseType !== "json") {
            return response
        }
        let res = response.data
        if (res.code === 200) {
            return response
        } else {
            Element.Message.error(!res.msg ? '系统异常' : res.msg)
            return Promise.reject(response.data.msg)
        }
    }, error => {
        if (error.response.data) {
            error.message = error.response.data.msg
        }

        if (error.response.status === 401) {
            router.push("/login")
        }

        Element.Message.error(error.message, {duration: 1000})
        return Promise.reject(error)
    }
)


export default request