<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "App",
  watch: {
    $route(to, from) {
      if (to.path !== '/login' && to.path !== '/') {
        let obj = {
          name: to.name,
          title: to.meta.title
        }
        this.$store.commit('addTab',obj)
      }
    }
  }
}
</script>
<style>
html, body, #app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  font-family: Source Han Sans CN Regular,sans,serif;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  margin: 0;

}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

a {
  text-decoration: none;
}
</style>
