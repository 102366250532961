<template class="el-menu-vertical">
  <el-menu
      :default-active=this.$store.state.menus.editableTabsValue
      class="el-menu-vertical menus"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      el-collapse-transition
      :collapse="isCollapse">
    <el-button  class="menuCollapseStyle" @click="doCollapse" :icon="isCollapse?'el-icon-s-fold':'el-icon-s-unfold'" @blur=""></el-button>
    <router-link to="/index">
      <el-menu-item index="Index"  @click="selectMenu({name:'Index',title:'首页'})">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
       </el-menu-item>
    </router-link>
    <el-submenu :index="menu.name" v-for="menu in menuList">
      <template slot="title">
        <i :class="menu.icon"></i>
        <span>{{ menu.title }}</span>
      </template>
      <router-link :to="item.path" v-for="item in menu.children">
        <el-menu-item :index="item.name"   @click="selectMenu(item)">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
        </el-menu-item>
      </router-link>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      isCollapse: false,
      menuCollapseStyle: 'zhanKai'
    }
  },
  computed:{
    menuList :{
      get(){
        return this.$store.state.menus.menuList
      }
    }
  },methods:{
    selectMenu(item){
      this.$store.commit('addTab',item)
    },
    doCollapse(){
      this.isCollapse = !this.isCollapse
      if(this.menuCollapseStyle === 'zhanKai'){
        this.menuCollapseStyle = 'shouQi'
      }else {
        this.menuCollapseStyle = 'zhanKai'
      }
    }
  }
}
</script>

<style scoped>

.el-menu-vertical {
  height: 100%;
}
.menus{
  position: relative;
  z-index: 1;
}
.menuCollapseStyle {
  background-color:transparent;
  border: transparent;
  position: relative;
  float: right;
  z-index: 10;
}
</style>