import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    state: {
        SummaryReport: JSON.parse(localStorage.getItem('SummaryReport')) == null ? [
            {label: "广告主填充数", show: true},
            {label: "给媒体填充数", show: true},
            {label: "dp点击数", show: true},
            {label: "dp调起数", show: true},
            {label: "激励视频完播数", show: true},
            {label: "广告主填充率", show: true},
            {label: "媒体侧填充率", show: true},
            {label: "曝光率", show: true},
            {label: "点击率", show: true},
            {label: "dp调起率", show: true},
            {label: "广告平台ecpm", show: true},
            {label: "广告平台ecpc", show: true},
            {label: "广告平台收入", show: true}] : JSON.parse(localStorage.getItem('SummaryReport')),
        DetailsReport: JSON.parse(localStorage.getItem('DetailsReport')) == null ? [
            {label: "所属ssp", show: true},
            {label: "流量渠道", show: true},
            {label: "流量应用名称", show: true},
            {label: "流量广告位名称", show: true},
            {label: "广告位类型", show: true},
            {label: "系统类型", show: true},
            {label: "广告主", show: true},
            {label: "广告主应用名称", show: true},
            {label: "广告主广告位名称", show: true},
            {label: "有效请求数", show: true},
            {label: "用户数", show: true},
            {label: "广告主填充数", show: true},
            {label: "给媒体填充数", show: true},
            {label: "曝光数", show: true},
            {label: "点击数", show: true},
            {label: "广告主曝光数", show: true},
            {label: "广告主点击数", show: true},
            {label: "dp点击数", show: true},
            {label: "dp调起数", show: true},
            {label: "激励视频完播数", show: true},
            {label: "广告主填充率", show: true},
            {label: "媒体侧填充率", show: true},
            {label: "曝光率", show: true},
            {label: "点击率", show: true},
            {label: "广告主曝光率", show: true},
            {label: "广告主点击率", show: true},
            {label: "dp调起率", show: true},
            {label: "广告平台Ecpm", show: true},
            {label: "广告平台Ecpc", show: true},
            {label: "媒体Ecpm", show: true},
            {label: "媒体Ecpc", show: true},
            {label: "收入", show: true},
            {label: "支出", show: true},
            {label: "rtb预估收入", show: true},
            {label: "rtb预估支出", show: true},
            {label: "利润", show: true}
        ] : JSON.parse(localStorage.getItem('DetailsReport'))
    },
    getters: {},
    mutations: {
        setDetailsReport: (state, DetailsReport) => {
            state.DetailsReport = DetailsReport
            localStorage.setItem('DetailsReport', JSON.stringify(state.DetailsReport))

        },
        setSummaryReport: (state, SummaryReport) => {
            state.SummaryReport = SummaryReport
            localStorage.setItem('SummaryReport', JSON.stringify(state.SummaryReport))
        },
    },
    actions: {},
    modules: {}
}
