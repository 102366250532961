<template>
  <div>
    <el-row>
      <el-col :span="24">
        <!-- .vue文件中的html部分-->
        <div id="myChart" style="width: 100%;height: 600px"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-form :inline="true" :model="searchForm">
            <el-form-item>
              <el-date-picker
                  v-model="searchForm.date"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyyMMdd"
                  :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchForm.uid" filterable clearable placeholder="所属账户">
                <el-option
                    v-for="item in uid_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="searchForm.appId"
                  clearable
                  placeholder="应用id">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="searchForm.adslotId"
                  clearable
                  placeholder="广告位id">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="searchForm.extAppId"
                  clearable
                  placeholder="广告主应用id">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="searchForm.extAdslotId"
                  clearable
                  placeholder="广告主广告位id">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchForm.osType" filterable clearable placeholder="系统类型">
                <el-option
                    v-for="item in os_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchForm.adslotType" filterable clearable placeholder="广告位类型">
                <el-option
                    v-for="item in adslot_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="getProfitInfoList" type="primary" icon="el-icon-search">搜索</el-button>
            </el-form-item>
          </el-form>
          <!--列表-->
          <el-table
              ref="multipleTable"
              border
              stripe
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%">
            <el-table-column
                prop="date"
                label="数据日期">
            </el-table-column>
            <el-table-column
                prop="income"
                label="收入">
            </el-table-column>
            <el-table-column
                prop="pay"
                label="支出">
            </el-table-column>
            <el-table-column
                prop="profit"
                label="利润">
            </el-table-column>
            <el-table-column
                prop="deductionProfit"
                label="扣减利润">
            </el-table-column>
            <el-table-column
                prop="totalProfit"
                label="总利润">
            </el-table-column>
          </el-table>
          <!--页码-->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-col>

    </el-row>
  </div>

</template>

<script>
import format from "@/plugins/DateFormat";
import * as echarts from 'echarts'

export default {
  name: "Index",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      current: 1,
      total: 0,
      size: 10,
      tableData: [],
      multipleSelection: [],
      delBtnStu: true,
      searchForm: {
        date: [],
      },
      os_type_options: [
        {
          label: 'Android',
          value: 'Android'
        },
        {
          label: 'iOS',
          value: 'iOS'
        }
      ],
      app_type_options: [
        {
          label: 'APP',
          value: 1,
        },
        {
          label: '快应用',
          value: 2
        }
      ],
      adslot_type_options: [
        {
          label: '横幅',
          value: 1,
        },
        {
          label: '插屏',
          value: 2
        },
        {
          label: '开屏',
          value: 3
        },
        {
          label: '信息流大图',
          value: 4
        },
        {
          label: '信息流小图',
          value: 5
        },
        {
          label: '信息流三图',
          value: 6
        },
        {
          label: '激励视频',
          value: 8
        },
      ],
      adslot_coop_options: [
        {
          label: '固定',
          value: 1,
        },
        {
          label: 'RTB',
          value: 3,
        }
        , {
          label: '分成',
          value: 2,
        }
        , {
          label: '其他',
          value: 4,
        }],
      uid_options: [],
      option: null,
      myChart: null
    }
  },
  mounted() {
    this.drawLine();//渲染echarts表格
    this.getProfitInfoList()
  },
  methods: {
    getUserListOptions() {
      this.$axios.get('/sys/user/list/options').then(res => {
        this.uid_options = res.data.data
      })
    },
    getProfitInfoList() {
      this.$axios.get('/settlement/profit/list', {
        params: {
          uid: this.searchForm.uid,
          appId: this.searchForm.appId,
          extAppId: this.searchForm.extAppId,
          adslotId: this.searchForm.adslotId,
          extAdslotId: this.searchForm.extAdslotId,
          osType: this.searchForm.osType,
          adslotType: this.searchForm.adslotType,
          start: this.searchForm.date[0],
          end: this.searchForm.date[1],
          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.pageData.records
        this.option.series[0].data = res.data.data.income
        this.option.series[2].data = res.data.data.pay
        this.option.series[1].data = res.data.data.profit
        this.option.xAxis.data = res.data.data.xdata
        this.current = res.data.data.pageData.current
        this.size = res.data.data.pageData.size
        this.total = res.data.data.pageData.total
        this.myChart.setOption(this.option);
      })
    },
    /**
     * 组件相关函数
     */
    handleSizeChange(val) {
      this.size = val
      this.getProfitInfoList()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getProfitInfoList()
    },
    drawLine() {
      //初始化echarts实例
      this.myChart = echarts.init(document.getElementById('myChart'));
      // 绘制图表
      this.option = {
        title: {
          text: '收益数据统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['收入', '利润', '支出' ]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          name: '时间维度',
          type: 'category',
          data: this.xDataArr,
        },
        yAxis: {
          name: '单位：元',
          type: 'value',
        },
        series: [
          {
            name: '收入',
            type: 'line',
            data: this.income
          },
          {
            name: '利润',
            type: 'line',
            data: this.profit
          },
          {
            name: '支出',
            type: 'line',
            data: this.pay
          },
          ]
      }
      this.myChart.setOption(this.option);
    }
  },
  created() {
    this.getUserListOptions()
    this.searchForm.date = this.timeDefault
  },
  computed: {
    // 默认时间
    timeDefault() {
      const end = new Date().toLocaleDateString();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [format(start, 'YYYYMMDD'), format(end, 'YYYYMMDD')]
    }
  },
}
</script>

<style scoped>

</style>